export default {
  'Your profile': 'Your profile',
  Settings: 'Settings',
  Login: 'Login',
  'Sign out': 'Sign out',
  Profile: 'Profile',
  'Activate 2FA': 'Activate 2FA',
  'Disable 2FA': 'Disable 2FA',
  Submit: 'Submit',
  Date: 'Date',
  Calendar: 'Calendar',
  'Open popup': 'Open popup',
  'Choose value': 'Choose value',
  Send: 'Send',
  'Your message has been sent. We will get in touch as soon as possible.':
    'Your message has been sent. We will get in touch as soon as possible.',
  'Something went wrong sending your message. Please try again later.':
    'Something went wrong with sending of your message. Try again later.',
  'Something went wrong with sending of your message. Try again later.':
    'Something went wrong with sending of your message. Try again later.',
  Dutch: 'Dutch',
  English: 'English',
  'Always a perfect gift': 'Always a perfect gift',
  'View the assortment': 'View the assortment',
  Cart: 'Cart',
  Search: 'Search',
  'Ordered before 15.00, shipped the same day':
    'Ordered before 15.00, shipped the same day',
  'Ordered before 15.00': 'Ordered before 15.00',
  'shipped the same day': 'shipped the same day',
  'Also digital gift cards': 'Also digital gift cards',
  'Digital gift cards': 'Digital gift cards',
  'directly in your email': 'directly in your email',
  'Pay easily with': 'Pay easily with',
  'iDeal, credit card or invoice': 'iDeal, credit card or invoice',
  'Easy and secure payment': 'Easy and secure payment',
  'Delivery to desired address and time':
    'Delivery to desired address and time',
  'Delivery to desired': 'Delivery to desired',
  'address and time': 'address and time',
  'View all gift cards': 'View all gift cards',
  'View gift card': 'View gift card',
  'View gift cards': 'View gift cards',
  Description: 'Description',
  'Product specifications': 'Product specifications',
  'Terms and conditions': 'Terms and conditions',
  Occasions: 'Occasions',
  Occasion: 'Occasion',
  'Choose one of our 100+ gift cards': 'Choose one of our 100+ gift cards',
  Categories: 'Categories',
  Filter: 'Filter',
  'Fetching products.': 'Fetching products.',
  'No gift cards found with these search criteria.':
    'No gift cards found with these search criteria.',
  'Most chosen gift cards.': 'Most chosen gift cards.',
  'Add to cart': 'Add to cart',
  'Choose your amount': 'Choose your amount',
  'Custom amount': 'Custom amount',
  Or: 'Or',
  'choose a custom amount': 'choose a custom amount',
  Quantity: 'Quantity',
  'Business order': 'Business order',
  'Choose your shipping method': 'Choose your shipping method',
  'Continue shopping': 'Continue shopping',
  'By post': 'By post',
  'By email': 'By email',
  Value: 'Value',
  'Order number': 'Order number',
  'Order as': 'Order as',
  Consumer: 'Consumer',
  Company: 'Company',
  'Company information': 'Company information',
  'Company name': 'Company name',
  'Tax number': 'Tax number',
  'KVK number': 'KVK number',
  'Billing address': 'Billing address',
  'Shipping information': 'Shipping information',
  Street: 'Street',
  'House number': 'House number',
  Addition: 'Addition',
  'Zip code': 'Zip code',
  City: 'City',
  'Send gift cards to a different address':
    'Send gift cards to a different address',
  'Your information': 'Your information',
  'First name': 'First name',
  'Last name': 'Last name',
  'Phone number': 'Phone number',
  'Email address': 'Email address',
  'Send gift cards to a different email address':
    'Send gift cards to a different email address',
  'Create account': 'Create account',
  Password: 'Password',
  'Confirm password': 'Confirm password',
  'Add a personal message (max 150 characters incl. spaces)':
    'Add a personal message (max 150 characters incl. spaces)',
  Message: 'Message',
  Amount: 'Amount',
  Subtotal: 'Subtotal',
  Shipping: 'Shipping',
  Total: 'Total',
  'I agree to the ': 'I agree to the ',
  'terms and conditions': 'terms and conditions',
  'Place order': 'Place order',
  Checkout: 'Checkout',
  checkout: 'checkout',
  'Log in to autocomplete your information':
    'Log in to autocomplete your information',
  Tax: 'Tax',
  'Continue to checkout': 'Continue to checkout',
  Price: 'Price',
  'Forgot password': 'Forgot password',
  'A reset link has been sent to the email address.':
    'A reset link has been sent to the email address.',
  'Something went wrong while requesting the password reset.':
    'Something went wrong while requesting the password reset.',
  'Your password has been reset.': 'Your password has been reset.',
  'Something went wrong while resetting the password.':
    'Something went wrong while resetting the password.',
  'Search for': 'Search for',
  'Occasion type': 'Occasion type',
  'Person type': 'Person type',
  Oops: 'Oops',
  "This page doesn't exist": "This page doesn't exist",
  'Back to home': 'Back to home',
  Voornaam: 'First name',
  Achternaam: 'Last name',
  'E-mailadres': 'Email address',
  Telefoonnummer: 'Phone number',
  Bericht: 'Message',
  'Desired delivery date': 'Desired delivery date',
  'Gift cards': 'Gift cards',
  'Thanks for your order!': 'Thanks for your order!',
  'We will get started with your order right away':
    'We will get started with your order right away',
  'Order more gift cards': 'Order more gift cards',
  Menu: 'Menu',
  'Close menu': 'Close menu',
  'Something went wrong while placing your order. Please try again later.':
    'Something went wrong while placing your order. Please try again later.',
  'Shipping method': 'Shipping method',
  'There is nothing in the shopping cart yet! Click here to add something!':
    'There is nothing in the shopping cart yet! Click here to add something!',
  'Apply filters': 'Apply filters',
  'View cart': 'View cart',
  'Product has been added to cart!': 'Product has been added to cart!',
  'You must accept the terms and conditions to continue.':
    'You must accept the terms and conditions to continue.',
  'Gewenste cadeaukaart': 'Desired gift card',
  'Aantal gewenste cadeaukaarten': 'Number of desired gift cards',
  'Bijzonderheden/opmerkingen': 'Details/comments',
  'Form has been submitted.': 'Form has been submitted.',
  'Something went wrong while submitting the form. Try again later.':
    'Something went wrong while submitting the form. Try again later.',
  'Repeat email address': 'Repeat email address',
  'For both physical and digital products':
    'For both physical and digital products',
  'Frequently asked questions': 'Frequently asked questions',
  'E-mailadres herhalen': 'Repeat email address',
  'Activate account': 'Activate account',
  'The password has been changed successfully, you are being redirected to the login page.':
    'The password has been changed successfully, you are being redirected to the login page.',
  'Activating your account...': 'Activating your account...',
  'Something went wrong while activating your account. Try again later.':
    'Something went wrong while activating your account. Try again later.',
  'Your account has been activated. You will be redirected to the login page.':
    'Your account has been activated. You will be redirected to the login page.',
  Hide: 'Hide',
  Show: 'Show',
  Processing: 'Processing',
  Canceled: 'Canceled',
  Completed: 'completed',
  'Order date': 'Order date',
  'Shipping cost': 'Shipping cost',
  'Payment method': 'Payment method',
  'Payment status': 'Payment status',
  'There has been an error': 'There has been an error',
  Product: 'Product',
  'Your order': 'Your order',
  'You paid with': 'You paid with',
  'VAT is not applicable on most gift cards. A VAT rate of 21% applies to shipping costs.': 'VAT is not applicable on most gift cards. A VAT rate of 21% applies to shipping costs.',
  'Download invoice': 'Download invoice',
  'The requested invoice is not (yet) available.': 'The requested invoice is not (yet) available.',
  Home: 'Home',
  'Do you already have an account?': 'Do you already have an account?',
  'Don\'t have an account yet? You can easily create this during': 'Don\'t have an account yet? You can easily create this during',
  'Read less': 'Read less',
  'Read more': 'Read more',
  'Choose one of our many well-known gift cards': 'Choose one of our many well-known gift cards',
  'An unknown error occurred. Please try again.': 'An unknown error occurred. Please try again.',
  'The payment was canceled. Please try again.': 'The payment was canceled. Please try again.',
  'The payment failed. Please try again.': 'The payment failed. Please try again.',
};
