import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import Vue from 'vue';
Vue.filter('money', function (value) {
  var minDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var maxDigits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;

  if (typeof value !== 'number') {
    value = parseFloat(value);
  }

  var formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits
  });
  return formatter.format(value);
});
Vue.filter('ucFirst', function (val) {
  return val.charAt(0).toUpperCase() + val.slice(1);
});