import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.split.js";
import Vue from 'vue';
import DynamicForm from '~/components/base/DynamicForm';

var stripLeftOverPs = function stripLeftOverPs(html) {
  if (html.endsWith('<p>')) {
    html = html.substring(0, html.length - 3);
  }

  if (html.startsWith('</p>')) {
    return html.substring(4);
  }

  return html;
};

Vue.component(DynamicForm);
Vue.component('DynamicText', {
  props: {
    html: {
      type: String,
      required: true
    }
  },
  render: function render(createElement) {
    var formIds = [];
    var elements = [];
    var splitter = '<|||||>';

    if (!this.html) {
      return '';
    }

    var html = this.html.replace(/{{FORM-(\d+)}}/g, function (match, id) {
      formIds.push(parseInt(id));
      return splitter;
    });
    html.split(splitter).forEach(function (part, index) {
      elements.push(createElement('span', {
        domProps: {
          innerHTML: stripLeftOverPs(part)
        }
      }));

      if (formIds[index]) {
        elements.push(createElement(DynamicForm, {
          props: {
            formId: formIds[index]
          }
        }));
      }
    });
    return createElement('div', {}, elements);
  }
});