//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://buyanygiftcard.com' + this.$route.path,
        },
      ],
    };
  },
};
