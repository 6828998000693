import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import { VuexPersistence } from 'vuex-persist';
export default (function (_ref) {
  var store = _ref.store;
  window.onNuxtReady(function () {
    new VuexPersistence({
      storage: window.localStorage,
      reducer: function reducer(state) {
        return {
          cart: state.cart
        };
      }
    }).plugin(store);
  });
});