import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";

// Better version of the routeOption method in @nuxtjs/auth that also includes checking for array content
var routeOption = function routeOption(route, key, value) {
  var checkIncludes = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var returnFound = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var found = null; // Loop over all the matched pages (will most likely always be only one match)

  route.matched.forEach(function (m) {
    // Loop over all the components in the page
    return Object.values(m.components).forEach(function (component) {
      if (process.server) {
        // Loop over the circular component structure to get the component options
        return Object.values(component._Ctor).forEach(function (ctor) {
          // If the component has options (not always true), set the found value to the desired options value.
          if (ctor.options) {
            found = ctor.options[key];
          }
        });
      } // The same as what's happening in the above if statement


      if (component.options) {
        found = component.options[key];
      }
    });
  });

  if (found && returnFound) {
    return found;
  } // Check if we need to check if the array includes our desired value and check if found is an array.


  if (checkIncludes && Array.isArray(found)) {
    return found && found.includes(value);
  }

  return found === value;
};

export default (function (_ref) {
  var store = _ref.store,
      route = _ref.route,
      redirect = _ref.redirect,
      localeRoute = _ref.localeRoute;

  if (store.state.whitelabel.enabled && routeOption(route, 'whitelabel', false)) {
    redirect(localeRoute({
      name: 'assortment-slug',
      params: {
        slug: store.state.whitelabel.product.slug
      }
    }));
  }
});