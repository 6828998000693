import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";

var scrollToError = function scrollToError(formName) {
  setTimeout(function () {
    var errorWrapperEl = document.querySelector("form[name=".concat(formName, "] [data-has-errors=\"true\"]"));
    var errorWrapperInput = errorWrapperEl && errorWrapperEl.querySelector('input,select,textarea');

    if (errorWrapperInput) {
      errorWrapperInput.focus();
      errorWrapperInput.scrollIntoView({
        block: 'center'
      });
    }
  });
}; // Todo: formErrors are always set to null, maybe we can use them in $toast?


export default (function (_ref, formName) {
  var graphQLErrors = _ref.graphQLErrors,
      response = _ref.response;
  var validation = null;

  if (graphQLErrors) {
    var validationErrors = graphQLErrors.find(function (error) {
      return error.message === 'validation';
    });

    if (validationErrors) {
      validation = validationErrors.extensions.validation;
    }
  }

  if (validation) {
    scrollToError(formName);
    return {
      inputErrors: validation,
      formErrors: null
    };
  } else if (response && response.status) {
    scrollToError(formName);

    switch (response.status) {
      case 422:
        return {
          inputErrors: response.data.errors,
          formErrors: null
        };
    }
  }

  return {
    inputErrors: {},
    formErrors: null
  };
});