import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Loader from '~/components/base/Loader';
export default {
  components: {
    Loader: Loader
  },
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  methods: {
    checkAttribute: function checkAttribute(attribute) {
      var foundAttribute = this.context.attributes[attribute];
      return foundAttribute && (foundAttribute === '' || foundAttribute === true);
    }
  }
};