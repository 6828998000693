import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { onError } from 'apollo-link-error';
export default function (_ref) {
  var _this = this;

  var app = _ref.app,
      req = _ref.req,
      nuxtError = _ref.error;

  // Try to include the cookie package and throw an error if it isn't installed
  try {
    require('cookie-universal-nuxt');
  } catch (e) {
    e.message = 'Missing cookie-universal-nuxt.';
    throw e;
  } // Handle errors thrown in GraphQL response


  var errorLink = onError(function (_ref2) {
    var graphQLErrors = _ref2.graphQLErrors,
        response = _ref2.response;

    if (graphQLErrors) {
      graphQLErrors.map(function (error) {
        if (error.extensions.category === 'authorization') {
          nuxtError({
            statusCode: 403,
            message: error.message
          });

          if (error.extensions.policy) {
            var _error$extensions$pol = error.extensions.policy,
                policy = _error$extensions$pol.policy,
                method = _error$extensions$pol.method;

            _this.$toast.error("Forbidden: ".concat(policy, "::").concat(method));
          }

          response.errors = null;
        }

        if (error.message === 'validation') {
          app.store.dispatch('validation/setErrors', error.extensions.validation);
        }
      });
    }
  }); // GraphQL link handler

  var linkOptions = {
    uri: process.env.GRAPHQL_URL,
    fetch: function (_fetch) {
      function fetch(_x, _x2) {
        return _fetch.apply(this, arguments);
      }

      fetch.toString = function () {
        return _fetch.toString();
      };

      return fetch;
    }(function (uri, options) {
      app.store.dispatch('validation/clearErrors'); // Add authorization token

      var strategy = app.store.state.auth.strategy;
      var token = app.$cookiz.get("auth_token.".concat(strategy));

      if (token) {
        options.headers.authorization = token;
      }

      options.headers['X-Locale'] = app.i18n.locale;

      if (process.server) {
        options.headers['x-forwarded-for'] = req.connection.remoteAddress || req.socket.remoteAddress;
      }

      return fetch(uri, options);
    })
  };
  return {
    inMemoryCacheOptions: {
      possibleTypes: {
        FilterOptionUnion: ['MoneyFilterOption', 'RegularFilterOption']
      }
    },
    link: errorLink,
    httpLinkOptions: linkOptions,
    batching: true
  };
}