import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    locale: {
      type: String,
      required: true
    }
  }
};