import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
export var state = function state() {
  return {
    enabled: false,
    logo: null,
    product: {}
  };
};
export var mutations = {
  setWhiteLabel: function setWhiteLabel(state, data) {
    state.enabled = true;
    state.logo = data.active_image.url;
    state.product = data.product;
  }
};
export var getters = {
  isEnabled: function isEnabled(state) {
    return state.enabled;
  },
  whiteLabelProduct: function whiteLabelProduct(state) {
    return state.product;
  },
  whiteLabelLogo: function whiteLabelLogo(state) {
    return state.logo;
  }
};