import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      options: {
        process: function process(pos) {
          return [[pos[0], pos[1]]];
        },
        enableCross: false
      }
    };
  },
  computed: {
    min: function min() {
      var _this$context$attribu;

      return ((_this$context$attribu = this.context.attributes) === null || _this$context$attribu === void 0 ? void 0 : _this$context$attribu.min) || null;
    },
    max: function max() {
      var _this$context$attribu2;

      return ((_this$context$attribu2 = this.context.attributes) === null || _this$context$attribu2 === void 0 ? void 0 : _this$context$attribu2.max) || null;
    }
  }
};