//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import occasions from '~/graphql/queries/occasions.gql';
import personTypes from '~/graphql/queries/personTypes.gql';

export default {
  apollo: {
    occasions: {
      query: occasions,
      variables() {
        return {
          limit: 5,
        };
      },
    },
    personTypes: {
      query: personTypes,
      variables() {
        return {
          limit: 5,
        };
      },
    },
  },
  data: () => ({
    open: false,
    flyOut: false,
    showSearch: false,
    languageSwitcherOpen: false,
    occasions: [],
    personTypes: [],
    flagMapping: {
      nl: 'icons-dutch-flag',
      en: 'icons-uk-flag',
    },
    searchForm: {
      query: '',
    },
  }),
  computed: {
    ...mapGetters({
      itemCount: 'cart/itemCount',
      whiteLabelLogo: 'whitelabel/whiteLabelLogo',
    }),
    mainMenu() {
      return this.$store.state.menuMain;
    },
    availableLocales() {
      return this.$i18n.locales;
    },
    openMenuButtonText() {
      return this.$t(this.open ? 'Close menu' : 'Menu');
    },
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
  },
  watch: {
    $route() {
      this.open = false;
    },
  },
  methods: {
    showOccasion(index) {
      this.$router.push(this.localePath({name: 'assortment', query: {occasions: index}}));
      this.flyOut = !this.flyOut;
    },
    showPerson(index) {
      this.$router.push(this.localePath({name: 'assortment', query: {persons: index}}));
      this.flyOut = !this.flyOut;
    },
    openFlyOut() {
      this.flyOut = !this.flyOut;
    },
    openDropdown() {
      this.open = !this.open;
    },
    search({query}) {
      this.$router.push(this.localePath({name: 'assortment', query: {query}}));
      this.searchForm.query = '';
    },
    closeLanguageSwitcher() {
      // TODO: currently doesn't work. Fix when we have more time.
      if (this.languageSwitcherOpen) {
        this.languageSwitcherOpen = false;
      }
    },
  },
};
