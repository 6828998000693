import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import { nl } from '@braid/vue-formulate-i18n';
import errorHandler from '~/libs/form-error-handler';
import Button from '~/components/formulate/Button';
import Date from '~/components/formulate/Date';
import Datepicker from '~/components/formulate/Calendar';
import Toggle from '~/components/formulate/Toggle';
import Value from '~/components/formulate/Value';
import RangeSlider from '~/components/formulate/RangeSlider';
Vue.component('Button', Button);
Vue.component('Date', Date);
Vue.component('Datepicker', Datepicker);
Vue.component('Toggle', Toggle);
Vue.component('Value', Value);
Vue.component('RangeSlider', RangeSlider);
export default {
  validationNameStrategy: ['validationName', 'label', 'placeholder', 'name', 'type'],
  library: {
    button: {
      classification: 'button',
      component: Button
    },
    submit: {
      classification: 'button',
      component: Button
    },
    date: {
      classification: 'date',
      component: Date
    },
    calendar: {
      classification: 'calendar',
      component: Datepicker,
      slotProps: {
        component: ['now']
      }
    },
    toggle: {
      classification: 'checkbox',
      component: Toggle
    },
    value: {
      classification: 'value',
      component: 'Value'
    },
    rangeSlider: {
      classification: 'none',
      component: RangeSlider
    }
  },
  plugins: [nl],
  classes: {
    input: function input(_ref) {
      var classification = _ref.classification,
          hasValue = _ref.hasValue;

      switch (classification) {
        case 'button':
          return 'w-full mt-3 rounded-full text-white bg-ocean text-lg leading-6 text-center p-6';

        case 'box':
          return 'rounded h-5 w-5 ring-primary';

        case 'group':
          return '';

        case 'file':
          return 'appearance-none opacity-0 cursor-pointer absolute inset-0 w-full h-full z-10';

        default:
          return 'transform-all block w-full py-3 px-4 appearance-none border border-border rounded-md focus:border-primary ring-0 outline-none';
      }
    },
    element: function element(_ref2) {
      var classification = _ref2.classification;

      switch (classification) {
        case 'box':
          return 'flex items-center';

        default:
          return 'w-full relative';
      }
    },
    outer: function outer(context) {
      if (context.isGrouped) {
        return '';
      } else {
        switch (context.classification) {
          case 'button':
            return '';

          case 'radio':
            return '';

          default:
            return '';
        }
      }
    },
    wrapper: function wrapper(context) {
      switch (context.classification) {
        case 'button':
          return '';

        case 'box':
          return 'w-full flex justify-start';

        case 'text':
        default:
          return '';
      }
    },
    label: function label(context) {
      switch (context.classification) {
        case 'box':
          return 'font-body flex-grow ml-2 text-left checkbox-label font-normal leading-6';

        default:
          return 'block mb-1 text-base text-gray-600';
      }
    },

    /* decorator: ({hasValue}) => {
      let base = 'border rounded border-gray focus:border-primary-light inline-block w-4 h-4 mr-2';
      if (hasValue) {
        base += ' bg-ocean';
      }
      return base;
    }, */
    help: 'text-xs my-1 text-gray-dark opacity-60',
    error: 'text-error text-xs mb-1',
    errors: 'mt-2',
    uploadArea: 'upload-area',
    file: ' appearance-none rounded border p-3 block flex relative items-center justify-center',
    files: 'hidden',
    uploadAreaMask: function uploadAreaMask(_ref3) {
      var loading = _ref3.attrs.loading;
      var base = 'absolute pointer-events-none flex justify-center items-center inset-0 border-2 border-dashed border-gray rounded upload-placeholder';

      if (loading) {
        return "".concat(base, " loading");
      } else {
        return base;
      }
    }
  },
  errorHandler: errorHandler
};