//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      date: null,
      modelConfig: {
        mask: 'DD-MM-YYYY'
      },
      attrs: [{
        key: 'today',
        highlight: {
          class: 'today'
        },
        dates: new Date()
      }]
    };
  },
  computed: {
    pickerAttributes: function pickerAttributes() {
      return Object.assign(this.context.attributes, {
        id: null,
        class: null
      });
    },
    isDisabled: function isDisabled() {
      return 'disabled' in this.context.attributes;
    }
  },
  watch: {
    date: function date(newDate) {
      this.context.model = this.$dateFns.format(newDate, 'yyyy-MM-dd');
    },
    'context.model': function contextModel(newValue) {
      this.date = new Date(newValue);
    }
  },
  mounted: function mounted() {
    if (this.context.model) {
      this.date = this.context.model;
    }

    this.context.model = this.date;
  },
  methods: {
    parseDate: function parseDate(value) {
      return this.$dateFns.isValid(value);
    }
  }
};