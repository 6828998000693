import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _015f7d89 = () => interopDefault(import('../pages/assortment/index.vue' /* webpackChunkName: "pages/assortment/index" */))
const _1fd26f78 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _6da576a1 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _c44ec610 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _bf70eeac = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8de84122 = () => interopDefault(import('../pages/landing.vue' /* webpackChunkName: "pages/landing" */))
const _fb3c120e = () => interopDefault(import('../pages/auth/forgot-password/index.vue' /* webpackChunkName: "pages/auth/forgot-password/index" */))
const _2ae210cc = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _7ddc4f9a = () => interopDefault(import('../pages/auth/profile.vue' /* webpackChunkName: "pages/auth/profile" */))
const _6469be7a = () => interopDefault(import('../pages/auth/registreren.vue' /* webpackChunkName: "pages/auth/registreren" */))
const _a0a1430c = () => interopDefault(import('../pages/auth/verifieer.vue' /* webpackChunkName: "pages/auth/verifieer" */))
const _309298c3 = () => interopDefault(import('../pages/templates/default.vue' /* webpackChunkName: "pages/templates/default" */))
const _79a0a018 = () => interopDefault(import('../pages/templates/faq.vue' /* webpackChunkName: "pages/templates/faq" */))
const _2727f03c = () => interopDefault(import('../pages/templates/langingspage.vue' /* webpackChunkName: "pages/templates/langingspage" */))
const _e2eb1eb2 = () => interopDefault(import('../pages/auth/activate-account/_token.vue' /* webpackChunkName: "pages/auth/activate-account/_token" */))
const _4fa47fe3 = () => interopDefault(import('../pages/auth/forgot-password/_token.vue' /* webpackChunkName: "pages/auth/forgot-password/_token" */))
const _0eb237de = () => interopDefault(import('../pages/auth/order-history/_uuid.vue' /* webpackChunkName: "pages/auth/order-history/_uuid" */))
const _620875cb = () => interopDefault(import('../pages/auth/setup-account/_token.vue' /* webpackChunkName: "pages/auth/setup-account/_token" */))
const _64ef4eda = () => interopDefault(import('../pages/checkout/invoice/_hash.vue' /* webpackChunkName: "pages/checkout/invoice/_hash" */))
const _108b93a8 = () => interopDefault(import('../pages/checkout/order/_uuid.vue' /* webpackChunkName: "pages/checkout/order/_uuid" */))
const _00ae477e = () => interopDefault(import('../pages/assortment/_slug.vue' /* webpackChunkName: "pages/assortment/_slug" */))
const _4ae3d7fc = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/assortiment",
    component: _015f7d89,
    pathToRegexpOptions: {"strict":true},
    name: "assortment___nl"
  }, {
    path: "/cart",
    component: _1fd26f78,
    pathToRegexpOptions: {"strict":true},
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _6da576a1,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___nl"
  }, {
    path: "/contact",
    component: _c44ec610,
    pathToRegexpOptions: {"strict":true},
    name: "contact___nl"
  }, {
    path: "/en",
    component: _bf70eeac,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/landing",
    component: _8de84122,
    pathToRegexpOptions: {"strict":true},
    name: "landing___nl"
  }, {
    path: "/auth/forgot-password",
    component: _fb3c120e,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password___nl"
  }, {
    path: "/auth/login",
    component: _2ae210cc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___nl"
  }, {
    path: "/auth/profile",
    component: _7ddc4f9a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-profile___nl"
  }, {
    path: "/auth/registreren",
    component: _6469be7a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren___nl"
  }, {
    path: "/auth/verifieer",
    component: _a0a1430c,
    pathToRegexpOptions: {"strict":true},
    name: "auth-verifieer___nl"
  }, {
    path: "/en/assortment",
    component: _015f7d89,
    pathToRegexpOptions: {"strict":true},
    name: "assortment___en"
  }, {
    path: "/en/cart",
    component: _1fd26f78,
    pathToRegexpOptions: {"strict":true},
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _6da576a1,
    pathToRegexpOptions: {"strict":true},
    name: "checkout___en"
  }, {
    path: "/en/contact",
    component: _c44ec610,
    pathToRegexpOptions: {"strict":true},
    name: "contact___en"
  }, {
    path: "/en/landing",
    component: _8de84122,
    pathToRegexpOptions: {"strict":true},
    name: "landing___en"
  }, {
    path: "/templates/default",
    component: _309298c3,
    pathToRegexpOptions: {"strict":true},
    name: "templates-default___nl"
  }, {
    path: "/templates/faq",
    component: _79a0a018,
    pathToRegexpOptions: {"strict":true},
    name: "templates-faq___nl"
  }, {
    path: "/templates/langingspage",
    component: _2727f03c,
    pathToRegexpOptions: {"strict":true},
    name: "templates-langingspage___nl"
  }, {
    path: "/en/auth/forgot-password",
    component: _fb3c120e,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password___en"
  }, {
    path: "/en/auth/login",
    component: _2ae210cc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___en"
  }, {
    path: "/en/auth/profile",
    component: _7ddc4f9a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-profile___en"
  }, {
    path: "/en/auth/registreren",
    component: _6469be7a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren___en"
  }, {
    path: "/en/auth/verifieer",
    component: _a0a1430c,
    pathToRegexpOptions: {"strict":true},
    name: "auth-verifieer___en"
  }, {
    path: "/en/templates/default",
    component: _309298c3,
    pathToRegexpOptions: {"strict":true},
    name: "templates-default___en"
  }, {
    path: "/en/templates/faq",
    component: _79a0a018,
    pathToRegexpOptions: {"strict":true},
    name: "templates-faq___en"
  }, {
    path: "/en/templates/langingspage",
    component: _2727f03c,
    pathToRegexpOptions: {"strict":true},
    name: "templates-langingspage___en"
  }, {
    path: "/en/auth/activate-account/:token?",
    component: _e2eb1eb2,
    pathToRegexpOptions: {"strict":true},
    name: "auth-activate-account-token___en"
  }, {
    path: "/en/auth/forgot-password/:token?",
    component: _4fa47fe3,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password-token___en"
  }, {
    path: "/en/auth/order-history/:uuid?",
    component: _0eb237de,
    pathToRegexpOptions: {"strict":true},
    name: "auth-order-history-uuid___en"
  }, {
    path: "/en/auth/setup-account/:token?",
    component: _620875cb,
    pathToRegexpOptions: {"strict":true},
    name: "auth-setup-account-token___en"
  }, {
    path: "/en/checkout/invoice/:hash?",
    component: _64ef4eda,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-invoice-hash___en"
  }, {
    path: "/en/checkout/order/:uuid?",
    component: _108b93a8,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-order-uuid___en"
  }, {
    path: "/auth/activate-account/:token?",
    component: _e2eb1eb2,
    pathToRegexpOptions: {"strict":true},
    name: "auth-activate-account-token___nl"
  }, {
    path: "/auth/forgot-password/:token?",
    component: _4fa47fe3,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password-token___nl"
  }, {
    path: "/auth/order-history/:uuid?",
    component: _0eb237de,
    pathToRegexpOptions: {"strict":true},
    name: "auth-order-history-uuid___nl"
  }, {
    path: "/auth/setup-account/:token?",
    component: _620875cb,
    pathToRegexpOptions: {"strict":true},
    name: "auth-setup-account-token___nl"
  }, {
    path: "/checkout/invoice/:hash?",
    component: _64ef4eda,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-invoice-hash___nl"
  }, {
    path: "/checkout/order/:uuid?",
    component: _108b93a8,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-order-uuid___nl"
  }, {
    path: "/en/assortment/:slug",
    component: _00ae477e,
    pathToRegexpOptions: {"strict":true},
    name: "assortment-slug___en"
  }, {
    path: "/assortiment/:slug",
    component: _00ae477e,
    pathToRegexpOptions: {"strict":true},
    name: "assortment-slug___nl"
  }, {
    path: "/en/*",
    component: _4ae3d7fc,
    pathToRegexpOptions: {"strict":true},
    name: "*___en"
  }, {
    path: "/",
    component: _bf70eeac,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/*",
    component: _4ae3d7fc,
    pathToRegexpOptions: {"strict":true},
    name: "*___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
