import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
export var state = function state() {
  return {
    orderAs: 'user',
    items: []
  };
};
export var mutations = {
  add: function add(state, data) {
    var existingItem = state.items.findIndex(function (item) {
      return item.product.id === data.product.id && item.amount === data.amount && item.product.fysiek === data.product.fysiek;
    });

    if (existingItem !== -1) {
      state.items[existingItem].quantity += data.quantity;
    } else {
      state.items.push(data);
    }
  },
  setQuantity: function setQuantity(state, _ref) {
    var id = _ref.id,
        quantity = _ref.quantity;
    var index = state.items.findIndex(function (i) {
      return i.product.id === id;
    });

    if (index !== -1) {
      state.items[index].quantity = parseInt(quantity);
    }
  },
  setAmount: function setAmount(state, _ref2) {
    var id = _ref2.id,
        amount = _ref2.amount;
    var index = state.items.findIndex(function (i) {
      return i.product.id === id;
    });

    if (index !== -1) {
      state.items[index].amount = amount;
    }
  },
  remove: function remove(state, cartItem) {
    var index = state.items.findIndex(function (i) {
      return i.product.id === cartItem.product.id && i.amount === cartItem.amount;
    });

    if (index !== -1) {
      state.items.splice(index, 1);
    }
  },
  clear: function clear(state) {
    state.items = [];
  },
  setOrderAs: function setOrderAs(state, orderAs) {
    state.orderAs = orderAs;
  }
};
export var getters = {
  itemCount: function itemCount(state) {
    return state.items.reduce(function (count, item) {
      return count + item.quantity;
    }, 0);
  },
  items: function items(state) {
    return state.items;
  }
};